import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { __RouterContext } from 'react-router';

import { fetchService, fetchArticle, fetchArticles } from '../utils/api';

let cacheEndpoint;
let cache = new Map();

/**
 * Fetches the right entity depending on the current URL
 * @param  {Object} router
 * @param  {String} endpoint
 * @return {Promise}
 */
async function fetchSpecFromUrl (router, endpoint) {
  const path = router.location.pathname || '/';
  let [ , type, value ] = path.split('/');

  if (!type) {
    type = 'article';
  }

  if (type === 'article') {
    if (!value) {
      const articles = await fetchArticles(endpoint);
      value = articles[0].key;
    }
    return fetchArticle(endpoint, value);
  }

  return fetchService(endpoint, value);
}

/**
 * Hook that returns the spec, the loading and the error state
 * Uses the react router internal context to get the current url information
 * @return {Object}
 */
function useSpecFromUrl () {
  const endpoint = useSelector((state) => state.endpoint);

  const router = useContext(__RouterContext);
  const routeKey = router.location.pathname;

  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ spec, setSpec ] = useState();

  useEffect(
    () => {
      if (endpoint !== cacheEndpoint) {
        cache = new Map();
        cacheEndpoint = endpoint;
      }

      if (cache.get(routeKey)) {
        setSpec(cache.get(routeKey));
        return;
      }

      setLoading(true);
      fetchSpecFromUrl(router, endpoint)
        .then((spec) => {
          cache.set(routeKey, spec);
          setSpec(spec);
          setError(null);
          setLoading(false);
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    },
    [ routeKey, endpoint ]
  );

  return { loading, error, spec };
}

export default useSpecFromUrl;
