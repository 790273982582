export const reducer = (state = window.innerWidth > 768, action) => {
  switch (action.type) {
    case 'drawer/SET':
      return action.payload;
    default:
      return state;
  }
};

export const actions = {
  show: () => ({ type: 'drawer/SET', payload: true }),
  hide: () => ({ type: 'drawer/SET', payload: false })
};
