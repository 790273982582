export const reducer = (state = process.env.REACT_APP_ENDPOINT, action) => {
  switch (action.type) {
    case 'endpoint/SET':
      return action.payload;
    default:
      return state;
  }
};

export const actions = {
  set: (payload) => ({ type: 'endpoint/SET', payload })
};
