import _ from 'lodash';

export function sort (methods) {
    const metadata = [
        'Search Result Formats',
        'Searchable Fields',
        'Search Extra Options',
        'Read Fields',
        'Read Extra Fields',
        'Order By Fields',
        'Delete Modes'
    ];

    // Using a sort index that's incremented each loop stops items from getting the same sort value
    // which stops the menu from randomly placing methods around when they have the same sort value.
    let sortIndex = 1;

    let methodPositions = {
        read: 1,
        create: 2,
        update: 3,
        search: 4,
        archive: 5,
        trash: 6,
        purge: 7,
        recoverFromArchive: 8,
        recoverFromTrash: 9,
        autocomplete: 10
    };

    _.forEach(methods, function (method, key) {
        if (!method.key) {
            method.key = method.name;
        }

        if (methodPositions[method.key]) {
            method.sort = methodPositions[method.key];
        } else if (_.startsWith(method.key, 'describe')) {
            method.sort = 1000 + sortIndex;
        } else if (metadata.includes(method.label)) {
            method.sort = 10000 + metadata.indexOf(method.key) + sortIndex;
        } else {
            method.sort = 100 + sortIndex;
        }

        sortIndex++;
    });

    return methods.sort((a, b) => (a.sort > b.sort ? 1 : -1));
}
