import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';

import SettingsDialog from './SettingsDialog';

import { withTheme } from '../theme';
import { actions as drawerActions } from '../redux/drawer';

function Header ({ classes }) {
  const [ showDialog, setShowDialog ] = useState(false);

  const showDrawer = useSelector((state) => state.drawer);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: showDrawer
        })}
      >
        <Toolbar disableGutters={!showDrawer}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => dispatch(drawerActions.show())}
            className={classNames(classes.menuButton, {
              [classes.hide]: showDrawer
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            className={classes.grow}
          >
            Rex API Docs
          </Typography>
          <Button color="inherit" onClick={() => setShowDialog(true)}>
            Settings
          </Button>
        </Toolbar>
      </AppBar>

      <SettingsDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
      />
    </Fragment>
  );
}

export default withTheme(Header);
