import { withStyles } from '@material-ui/core/styles';

const DRAWER_WIDTH = 320;

export const styles = (theme) => ({
  root: {
    display: 'flex'
  },

  appBar: {
    transition: theme.transitions.create([ 'margin', 'width' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create([ 'margin', 'width' ], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },

  hide: {
    display: 'none'
  },

  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0
  },

  drawerPaper: {
    width: DRAWER_WIDTH
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },

  content: {
    flexGrow: 1,
    padding: 0,
    overflowX: 'hidden',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -DRAWER_WIDTH
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },

  grow: {
    flexGrow: 1
  },

  inputRoot: {
    color: 'inherit',
    width: '100%'
  },

  inputInput: {
    padding: theme.spacing.unit,
    transition: theme.transitions.create('width'),
    width: '100%'
  },

  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    textAlign: 'center'
  },

  loading: {
    margin: '50px auto',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: '#3f51b5',
    flex: 1
  },

  error: {
    margin: '0 auto',
    padding: '40px 20px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: '#ff3860',
    flex: 1,
    fontSize: '14px'
  },

  nested: {
    padding: '7px 16px 7px 32px'
  },

  nestedText: {
    fontSize: '13px !important',
    color: 'rgba(0, 0, 0, .4) !important'
  },

  nestedSelected: {
    backgroundColor: '#f4f4f4'
  }
});

// Abstracting this out, since we want to use it in several components
// so this makes it a bit easier
export const withTheme = withStyles(styles, { withTheme: true });
