import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Header from './Header';
import Sidebar from './Sidebar';
import Content from './Content';

import { withTheme } from '../theme';

function App ({ classes }) {
  return (
    <div className={classes.root}>
      <Header />
      <Sidebar />
      <Content />
      <CssBaseline />
    </div>
  );
}

export default withTheme(App);
