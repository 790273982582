import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { __RouterContext } from 'react-router';
import _ from 'lodash';

import Drawer from '@material-ui/core/Drawer';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import ServicesList from './ServicesList';
import Subheader from './Subheader';

import { withTheme } from '../theme';
import { actions as drawerActions } from '../redux/drawer';
import { fetchArticles, fetchServices } from '../utils/api';

/**
 * Money patches history methods to allow us to hook into redox internal
 * behavior
 * https://stackoverflow.com/questions/5129386/how-to-detect-when-history-pushstate-and-history-replacestate-are-used
 * @param  {String} type
 * @return {Function}
 */
function monkeyPatch (type) {
  const original = window.history[type];
  return function (...args) {
    const rv = original.apply(this, args);
    const e = new Event('customHashchange');
    e.arguments = args;
    window.dispatchEvent(e);
    return rv;
  };
}

// HACK: we need to patch these to get access to when redoc changes the location hash
// depending on the users scroll position, which we need to set the currently active
// sub menu
window.history.pushState = monkeyPatch('pushState');
window.history.replaceState = monkeyPatch('replaceState');

/**
 * Util to create filter function for articles and services, which we use to apply
 * the search field input
 * @param  {String} search
 * @return {Function}
 */
function createFilter (search) {
  return (item) => item.name.toLowerCase().includes(search.toLowerCase());
}

function Sidebar ({ classes, theme }) {
  const router = useContext(__RouterContext);
  const [ search, setSearch ] = useState('');

  const [ , routeType, routeValue ] = router.location.pathname.split('/');

  const [ loading, setLoading ] = useState([]);
  const [ error, setError ] = useState(null);
  const [ articles, setArticles ] = useState([]);
  const [ services, setServices ] = useState({});

  const dispatch = useDispatch();
  const drawer = useSelector((state) => state.drawer);
  const endpoint = useSelector((state) => state.endpoint);

  // Load articles and services on mount and every time the user changes
  // the endpoint
  useEffect(
    () => {
      setLoading([ 'articles', 'services' ]);
      setError(null);

      Promise.all([
        fetchArticles(endpoint).then((articles) => {
          setLoading((state) => state.filter((key) => key !== 'articles'));
          setArticles(articles);
          setError(null);
        }),
        fetchServices(endpoint).then((services) => {
          setLoading((state) => state.filter((key) => key !== 'services'));
          setServices(services);
          setError(null);
        })
      ])
        .then(() => {
          setError(null);
          setLoading([]);
        })
        .catch((e) => {
          setLoading([]);
          setArticles([]);
          setServices([]);
          setError(e.message);
        });
    },
    [ endpoint ]
  );

  useEffect(
    () => {
      const el = document.querySelector('[data-selected=true]');
      if (!loading && el) {
        // Using `previousSibling` here, cause otherwise the selected menu item
        // gets scrolled behind the sticky section header :/
        el.previousSibling.scrollIntoView();
      }
    },
    [ loading ]
  );

  // HACK: here we listen to the custom events we emit through the monkey patching
  // above whenever redox uses `history.replaceState` to change the hash
  const [ hash, setHash ] = useState(router.location.hash);
  const historyListener = useCallback(() => {
    setHash(window.location.hash);
  });
  useEffect(() => {
    window.addEventListener('customHashchange', historyListener, false);
    return () =>
      window.removeEventListener('customHashchange', historyListener);
  }, []);

  // Filter articles depending on current search value, only rerun this
  // when search value, articles or services changed
  const [ fArticles, setFilteredArticles ] = useState([]);
  const [ fServices, setFilteredServices ] = useState([]);
  useEffect(
    () => {
      setFilteredArticles(
        search ? articles.filter(createFilter(search)) : articles
      );
      setFilteredServices(
        search
          ? {
              documented: (services.documented || []).filter(createFilter(search)),
              undocumented: (services.undocumented || []).filter(createFilter(search))
            }
          : services
      );
    },
    [ search, articles, services ]
  );

  // We want to debounce the search for performance reason, to not run the filter
  // effect unneccesarily often
  const handleChange = _.debounce((e) => setSearch(e.target.value), 150);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={drawer}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          onChange={(e) => {
            e.persist();
            handleChange(e);
          }}
        />
        <IconButton onClick={() => dispatch(drawerActions.hide())}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>

      <Divider />

      {!!error && (
        <div className={classes.spinnerContainer}>
          <span className={classes.error}>{error}</span>
        </div>
      )}

      {loading.includes('articles') ? (
        <div className={classes.spinnerContainer}>
          <span className={classes.loading}>Loading data...</span>
        </div>
      ) : (
        <Fragment>
          {!!fArticles.length && (
            <List
              subheader={<Subheader component="div">Getting Started</Subheader>}
            >
              {fArticles.map((article) => (
                <ListItem
                  button
                  key={article.key}
                  selected={
                    routeType === 'article' && routeValue === article.key
                  }
                  onClick={() => router.history.push(`/article/${article.key}`)}
                >
                  <ListItemText>{article.name}</ListItemText>
                </ListItem>
              ))}
            </List>
          )}

          {loading.includes('services') ? (
            <Fragment>
              <Divider />
              <div className={classes.spinnerContainer}>
                <span className={classes.loading}>Loading services...</span>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <ServicesList
                title="Services with full documentation"
                services={fServices.documented}
                hash={hash}
              />
              <ServicesList
                title="Services with basic documentation"
                services={fServices.undocumented}
                hash={hash}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Drawer>
  );
}

export default withTheme(Sidebar);
