import React, { Fragment, useContext } from 'react';
import { __RouterContext } from 'react-router';
import styled from 'styled-components';
import classNames from 'classnames';
import _ from 'lodash';

import { sort } from '../utils/service-method-sorter';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import Subheader from './Subheader';

import { withTheme } from '../theme';

const NestedText = styled.span`
  color: rgba(0, 0, 0, .7);
  font-size: 13px;
  font-weight: 300;
`;

const Ellipsis = styled.span`
  overflow: hidden; 
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  width: 100%;
  display: block;
`;

function ServicesList ({ services, title, classes, hash }) {
  // We use the current router location as source of truth for the main content section,
  // so we also use it here to determine which menu items are `selected`
  const router = useContext(__RouterContext);
  const [ , routeType, routeValue ] = router.location.pathname.split('/');

  if (!services || !services.length) {
    return null;
  }

  return (
    <Fragment>
      <Divider />
      <List subheader={<Subheader component="div">{title}</Subheader>}>
        {services.map((service, index) => {
          service.methods = sort(service.methods);

          const selected =
            routeType === 'service' &&
            _.kebabCase(routeValue) === _.kebabCase(service.name);
          return (
            <Fragment key={service.label}>
              <ListItem
                button
                selected={selected}
                onClick={() =>
                  router.history.push(`/service/${_.kebabCase(service.name)}`)}
                title={service.label}
                data-selected={selected}
              >
                <ListItemText>
                  <Ellipsis>{service.label}</Ellipsis>
                </ListItemText>
              </ListItem>
              {!!Object.keys(service.methods).length && (
                <Collapse in={selected} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {service.methods.map((method, index) => {
                      const methodHash = `#operation/${_.kebabCase(
                        method.name
                      )}`;
                      
                      const methodSelected = hash === methodHash;

                      return (
                        <ListItem
                          key={method.label}
                          button
                          className={classNames(classes.nested, {
                            [classes.nestedSelected]: methodSelected
                          })}
                          onClick={() =>
                            router.history.push(
                              `/service/${_.kebabCase(
                                service.name
                              )}${methodHash}`
                            )}
                          title={method.description}
                        >
                          <ListItemText>
                            <NestedText>
                              <Ellipsis>{method.label}</Ellipsis>
                            </NestedText>
                          </ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </List>
    </Fragment>
  );
}

export default withTheme(ServicesList);
