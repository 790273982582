import React from 'react';
import { useSelector } from 'react-redux';
import { RedocStandalone } from '@rexlabs/redoc';
import classNames from 'classnames';
import styled from 'styled-components';

import { withTheme } from '../theme';
import useSpecFromUrl from '../hooks/useSpecFromUrl';

// HACK: since the browser (+ redoc) handles the scroll to id for the hashes,
// without this the actual section heading would be behind the Header component,
// so we override the redoc styles here to add extra paddings and move the
// divider line
const Wrapper = styled.div`
  & [data-section-id] {
    padding-top: 80px;
    position: relative;

    &:after {
      position: absolute;
      width: 100%;
      display: block;
      content: '';
      border-bottom: 1px solid rgba(0, 0, 0, .2);
      bottom: auto;
      top: 40px;
    }
  }

  & [data-section-id]:first-child {
    padding-top: 50px;
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }
`;

function Content ({ classes, spacing }) {
  const { loading, error, spec } = useSpecFromUrl();
  const drawer = useSelector((state) => state.drawer);

  return (
    <main
      className={classNames(classes.content, {
        [classes.contentShift]: drawer
      })}
    >
      <div className={classes.drawerHeader} />

      {loading ? (
        <div className={classes.spinnerContainer}>
          <span className={classes.loading}>Loading content...</span>
        </div>
      ) : error ? (
        <div className={classes.spinnerContainer}>
          <span className={classes.error}>{error}</span>
        </div>
      ) : null}

      {!loading &&
      !error &&
      spec && (
        <Wrapper>
          <RedocStandalone
            spec={spec}
            options={{
              disableSearch: false,
              hideSidebar: true,
              hideInfo: true,
              hideHostname: true,
              theme: {
                spacing: {
                  unit: 5,
                  sectionHorizontal: ({ spacing }) => spacing.unit * 8,
                  sectionVertical: ({ spacing }) => spacing.unit * 4
                },
                menu: {
                  width: '0px'
                }
              }
            }}
          />
        </Wrapper>
      )}
    </main>
  );
}

export default withTheme(Content);
