import React, { useState, useContext } from 'react';
import { __RouterContext } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { actions as endpointActions } from '../redux/endpoint';

function SettingsDialog ({ isOpen, onClose }) {
  const router = useContext(__RouterContext);

  const dispatch = useDispatch();
  const endpoint = useSelector((state) => state.endpoint);

  const [ value, setValue ] = useState(endpoint);

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <form
        id="settings"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(endpointActions.set(value));
          router.history.push('/');
          onClose();
        }}
      >
        <DialogTitle id="form-dialog-title">Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Docs are self generated using the describe API endpoints in Rex API.
            Change the base url here to generate the docs using a different
            instance of Rex API.
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="endpoint"
            label="API base url"
            type="text"
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form="settings" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default SettingsDialog;
