import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { reducer as drawer } from './drawer';
import { reducer as endpoint } from './endpoint';

// Persist specific parts of the store in localStorage
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [ 'endpoint', 'drawer' ]
};

const store = createStore(
  persistReducer(persistConfig, combineReducers({ drawer, endpoint })),
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
