import _ from 'lodash';
import { sort } from './service-method-sorter';

function addExperimentalFlag(isExperimental, name) {
  return `${isExperimental ? '[EXPERIMENTAL]' : ''} ${name}`.trim();
}

export function support (description) {
  return {
    openapi: '3.0.0',
    info: {
      version: '2.0',
      title: 'Wings API',
      description
    },
    servers: [],
    paths: {}
  };
}

export function generate (definition) {
  let doc = {
    openapi: '3.0.0',
    info: {
      version: '2.0',
      title: 'Wings API',
      description: 'Wings is the API of Rex. Prepare to lift off!'
    },
    servers: [],
    paths: {}
  };

  doc.servers.push({
    url: definition.url
  });

  let services = definition.services;

  services.forEach((service) => {
    services.methods = sort(service.methods);

    service.methods.forEach((method) => {
      const endpoint = `/${_.kebabCase(service.key)}/${_.kebabCase(
        method.key
      )}`;
      const operationId = method.key;
      let requestBody = null;
      let responses = null;
      const serviceName = addExperimentalFlag(service.experimental, service.key);
      const methodName = addExperimentalFlag(method.experimental, method.label)

      if (method.request) {
        requestBody = generateRequestBody(service, method);
      }

      if (method.response) {
        responses = generateResponses(service, method);
      }

      const path = {
        post: {
          operationId: operationId,
          summary: methodName,
          description: method.description,
          tags: [serviceName]
        }
      };

      if (requestBody) {
        path.post.requestBody = requestBody;
      }

      if (responses) {
        path.post.responses = responses;
      }

      doc.paths[endpoint] = path;
    });
  });

  return doc;
}

export function generateRequestBody (service, method) {
  let requestBody = {
    content: {
      'application/json': {}
    }
  };

  const data = generateRequestParameters(service, method);
  const examples = generateRequestExamples(service, method);

  _.set(requestBody, 'content.application/json', { schema: data });

  if (!_.isEmpty(examples)) {
    requestBody['content']['application/json']['examples'] = examples;
  }

  return requestBody;
}

export function generateResponses (service, method) {
  let responses = {};

  Object.keys(method.response.examples).forEach((exampleKey) => {
    const example = method.response.examples[exampleKey];
    const responseBody = {
      description: example.summary,
      content: {
        'application/json': {
          schema: {
            type: 'object'
          },
          examples: {
            Sample: {
              summary: '',
              value: example.example
            }
          }
        }
      }
    };

    responses[example.code] = responseBody;
  });

  return responses;
}

export function generateRequestParameters (service, method) {
  let properties = {};
  let required = [];

  Object.keys(method.request.parameters).forEach((parameterKey) => {
    const param = method.request.parameters[parameterKey];
    const definition = _.omit(param.definition, [ 'types' ]);

    if (_.get(param, 'definition.types', []).length > 0) {
      definition['anyOf'] = [];

      param.definition.types.forEach((t) => {
        definition['anyOf'].push({ type: t });
      });
    }

    if (definition) {
      properties[parameterKey] = definition;
    }

    if (param.required) {
      required.push(parameterKey);
    }
  });

  let data = { type: 'object' };

  if (!_.isEmpty(properties)) {
    data.properties = properties;
  }

  if (required.length > 0) {
    data.required = required;
  }

  return data;
}

export function generateRequestExamples (service, method) {
  let examples = {};

  Object.keys(method.request.examples).forEach((exampleKey) => {
    const example = method.request.examples[exampleKey];
    examples[exampleKey] = {
      summary: '',
      value: example
    };
  });

  return examples;
}
