import axios from 'axios';
import json2md from 'json2md';
import mt from 'markdown-table';
import _ from 'lodash';

import { support, generate } from './open-api';

export const api = axios.create();
api.interceptors.response.use((response) => {
  if (response.data.error) {
    throw new Error(response.data.error.message || 'Something went wrong!');
  }
  return response;
});

/**
 * Fetch all services for the side menu, this will also group the services
 * into `documented` and `undocumented` batches
 * @param  {String} endpoint
 * @return {Object}
 */
export async function fetchServices (endpoint) {
  const response = await api.post(endpoint + 'ApiDocs::getAvailableServices', {
    include_methods: 1
  });

  return response.data.result.reduce(
    (acc, serviceDetails) => {
      serviceDetails.label = _.startCase(serviceDetails.name);
      serviceDetails.methods = (serviceDetails.methods || []).map((method) => ({
        ...method,
        label: _.startCase(method.name).toLowerCase(),
        name: _.kebabCase(method.name)
      }));
      if (serviceDetails.has_openapi_docs) {
        acc.documented.push(serviceDetails);
      } else {
        acc.undocumented.push(serviceDetails);
      }

      return acc;
    },
    { documented: [], undocumented: [] }
  );
}

/**
 * Fetch all articles for the side menu
 * @param  {String} endpoint
 * @return {Array}
 */
export async function fetchArticles (endpoint) {
  const response = await api.post(endpoint + 'ApiDocs::getAvailableArticles');
  return Object.keys(response.data.result).map((key) => ({
    key,
    name: response.data.result[key]
  }));
}

/**
 * Fetch specific service for details screen, also transforms the result into
 * the expected spec shape for redoc
 * @param  {String} endpoint
 * @param  {String} serviceName
 * @return {Object}
 */
export async function fetchService (endpoint, serviceName) {
  const apiService = _.startCase(serviceName).replace(/\s/g, '');
  const response = await api.post(endpoint + `${apiService}::describe`, {
    include_detail: 1
  });
  const describe = response.data.result;

  let definition = {
    url: endpoint,
    services: []
  };

  let service = {
    key: _.startCase(serviceName),
    experimental: _.get(describe, 'experimental', false),
    methods: [],
    uncovered: []
  };

  let isModel = false;

  if (describe) {
    Object.keys(describe.methods).forEach((methodKey) => {
      let method = describe.methods[methodKey];

      method.openapi.key = _.kebabCase(methodKey);
      method.openapi.label = _.startCase(methodKey).toLowerCase();
      method.openapi.experimental = _.get(method, 'experimental', false);

      if (method.openapi.available) {
        service.methods.push(method.openapi);
      } else {
        service.uncovered.push(method.openapi);
      }

      if (methodKey === 'describeModel') {
        isModel = true;
      }
    });
  }

  if (isModel) {
    const model = await api.post(endpoint + `${serviceName}::describeModel`);

    if (
      model.data.result.search_result_formats &&
      model.data.result.search_result_formats.length > 0
    ) {
      var searchResultFormats = [];
      model.data.result.search_result_formats.forEach((f) => {
        searchResultFormats.push([ f.id, f.limit, f.description ]);
      });

      service.methods.push({
        available: true,
        description: json2md([
          {
            p:
              'The following table shows the available search result formats that can be returned via this service and the maximum number of records returned for each.'
          },
          {
            table: {
              headers: [ 'ID', 'Limit', 'Description' ],
              rows: searchResultFormats
            }
          }
        ]),
        key: 'search-result-formats',
        label: 'Search Result Formats'
      });
    }

    if (
      model.data.result.searchable_fields &&
      Object.keys(model.data.result.searchable_fields).length > 0
    ) {
      var searchableFields = [];

      if (Array.isArray(model.data.result.searchable_fields)) {
        model.data.result.searchable_fields.forEach((f) => {
          searchableFields.push([ f, '-', '-' ]);
        });
      } else {
        Object.keys(model.data.result.searchable_fields).forEach((k) => {
          var f = model.data.result.searchable_fields[k];
          searchableFields.push([
            f.name,
            f.type ? f.type : '-',
            f.options && f.options.list ? f.options.list : '-'
          ]);
        });
      }

      service.methods.push({
        available: true,
        description: json2md([
          {
            table: {
              headers: [ 'ID', 'Type', 'Value List ID' ],
              rows: searchableFields
            }
          }
        ]),
        key: 'searchable-fields',
        label: 'Searchable Fields'
      });
    }

    if (
      model.data.result.search_extra_options &&
      Object.keys(model.data.result.search_extra_options).length > 0
    ) {
      var extraOptions = [];

      Object.keys(model.data.result.search_extra_options).forEach((k) => {
        var f = model.data.result.search_extra_options[k];

        if (typeof f === 'string' || f instanceof String) {
          extraOptions.push([ k, f ]);
        } else {
          var options = f.description;
          if (f.fields && Object.keys(f.fields).length > 0) {
            options = options + '<ul>';
            Object.keys(f.fields).forEach((e) => {
              options =
                options +
                '<li><b>' +
                e +
                '</b> : <i>' +
                f.fields[e] +
                '</i></li>';
            });

            options = options + '</ul>';
          }

          extraOptions.push([ k, options ]);
        }
      });

      service.methods.push({
        available: true,
        description: json2md([
          {
            table: {
              headers: [ 'Field', 'Description' ],
              rows: extraOptions
            }
          }
        ]),
        key: 'search-extra-options',
        label: 'Search Extra Options'
      });
    }

    if (
      model.data.result.read_fields &&
      Object.keys(model.data.result.read_fields).length > 0
    ) {
      var readFields = [];

      Object.keys(model.data.result.read_fields).forEach((k) => {
        var f = model.data.result.read_fields[k];
        readFields.push([ k, f ]);
      });

      service.methods.push({
        available: true,
        description: json2md([
          {
            table: {
              headers: [ 'Field', 'Description' ],
              rows: readFields
            }
          }
        ]),
        key: 'read-fields',
        label: 'Read Fields'
      });
    }

    if (
      model.data.result.read_extra_fields &&
      Object.keys(model.data.result.read_extra_fields).length > 0
    ) {
      var readExtraFields = [];

      Object.keys(model.data.result.read_extra_fields).forEach((k) => {
        var f = model.data.result.read_extra_fields[k];
        readExtraFields.push([ k, f ]);
      });

      service.methods.push({
        available: true,
        description: json2md([
          {
            table: {
              headers: [ 'Field', 'Description' ],
              rows: readExtraFields
            }
          }
        ]),
        key: 'read-extra-fields',
        label: 'Read Extra Fields'
      });
    }

    if (
      model.data.result.orderby_fields &&
      Object.keys(model.data.result.orderby_fields).length > 0
    ) {
      var orderbyFields = [];
      orderbyFields.push([ 'Field' ]);

      Object.keys(model.data.result.orderby_fields).forEach((k) => {
        var f = model.data.result.orderby_fields[k];
        orderbyFields.push([ f ]);
      });

      service.methods.push({
        available: true,
        description: mt(orderbyFields),
        key: 'order-by-fields',
        label: 'Order By Fields'
      });
    }

    if (
      model.data.result.delete_modes &&
      Object.keys(model.data.result.delete_modes).length > 0
    ) {
      var deleteModes = [];
      deleteModes.push([ 'Mode' ]);

      Object.keys(model.data.result.delete_modes).forEach((k) => {
        var f = model.data.result.delete_modes[k];
        deleteModes.push([ f ]);
      });

      service.methods.push({
        available: true,
        description: mt(deleteModes),
        key: 'delete-modes',
        label: 'Delete Modes'
      });
    }

    definition.services.push(service);
    return generate(definition);
  }

  definition.services.push(service);
  return generate(definition);
}

/**
 * Fetch specific article for the details screen and transform it into expected
 * spec shape for redoc
 * @param  {String} endpoint
 * @param  {String} articleName
 * @return {Object}
 */
export async function fetchArticle (endpoint, articleName) {
  const response = await api.post(endpoint + 'ApiDocs::getArticle', {
    article_name: articleName
  });

  const doc = response.data.result.data;
  return support(doc);
}
